<template>
  <div class="content">
    <div class="titleWrap">
      <div class="titleText">
        <p>{{ startAddress }}</p>
        <img
          :src="tripType === 'OW' ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhuiHEeDUSY4uW1609075234.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/15/yuelvhuiwYaqChQanm1609141435.png'"
          alt=""
        >
        <p>{{ endAddress }}</p>
      </div>
    </div>
    <!--        去程-->
    <div class="tickedTimeDetail">
      <div class="AviationInfo">
        <p><span>{{ TravelText1 }}</span></p>
        <img :src="priceInfoD.flightCompanyLogo" alt="" style="margin-left: 28px;margin-top: -5px;">
        <p>{{ priceInfoD.flightCompanyName }}{{ priceInfoD.fn }}</p>
        <p>{{ priceInfoD.date }}</p>
        <p>{{ priceInfoD.weekDate }}</p>
      </div>
      <div class="TimeInfo">
        <div class="stareTimeInfo">
          <p>{{ priceInfoD.departureDayTime }}</p>
          <p>{{ priceInfoD.departureAirport }}</p>
        </div>
        <div class="TimeImg">
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/21/yuelvhuiLoDKVslO4A1609163787.png"
            alt=""
          >
        </div>
        <div class="endTimeInfo">
          <p>{{ priceInfoD.arrivalDayTime }}</p>
          <p>{{ priceInfoD.arrivalAirport }}</p>
        </div>
      </div>
      <div class="GiftInfo">
        {{ priceInfoD.haveMealName }} | {{ priceInfoD.planeCode }}
      </div>
    </div>
    <!--        返程-->
    <div class="tickedTimeDetail2">
      <div class="AviationInfo">
        <p><span>{{ TravelText2 }}</span></p>
        <img :src="priceInfoB.flightCompanyLogo" alt="" style="margin-left: 28px;margin-top: -5px;">
        <p>{{ priceInfoB.flightCompanyName }}{{ priceInfoB.fn }}</p>
        <p>{{ priceInfoB.date }}</p>
        <p>{{ priceInfoB.weekDate }}</p>
      </div>
      <div class="TimeInfo">
        <div class="stareTimeInfo">
          <p>{{ priceInfoB.departureDayTime }}</p>
          <p>{{ priceInfoB.departureAirport }}</p>
        </div>
        <div class="TimeImg">
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/21/yuelvhuiLoDKVslO4A1609163787.png"
            alt=""
          >
        </div>
        <div class="endTimeInfo">
          <p>{{ priceInfoB.arrivalDayTime }}</p>
          <p>{{ priceInfoB.arrivalAirport }}</p>
        </div>
      </div>
      <div class="GiftInfo">
        {{ priceInfoB.haveMealName }} | {{ priceInfoB.planeCode }}
      </div>
      <div class="priceTipsInfo">
        <div class="priceTipsInfoContent">
          <p>成人票：¥{{ tickPriceInfo.price }}+基建燃油费¥{{ adultFuelCostsOrtax }};</p>
          <p style="margin-left: -2px;">儿童票：¥{{ tickPriceInfo.childPrice }}+基建燃油费¥0;</p>
          <!--                    <div class="tickedListLeftContent" @click="showNote" style="margin-left: -10px;">-->
          <!--                        <p>-->
          <!--                            <span>{{baseCabinName}}{{discount}}折 | 退改¥{{refundExp}}起 | </span>-->
          <!--                            <span v-if="weight === 0">无免费托运行李额</span>-->
          <!--                            <span v-else>托运行李{{weight}}KG</span>-->
          <!--                        </p>-->
          <!--                        <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/23/yuelvhuik6S77Dgbb31609169525.png" alt="">-->
          <!--                    </div>-->
          <p>因为机票信息随时变化，以及网络传输的问题，价格可能存在误差，请以最终支付金额为准。</p>
        </div>
      </div>
      <div class="RoundTripTips">
        <p>此价格为往返组合产品：<span>支付成功后将分开出票。出票后，如其中一段发生航班变动，另一段行程的改签费用需旅客自行承担。</span></p>
      </div>
    </div>
    <!--        联系人-->
    <div style="padding-bottom: 75px;">
      <!--        乘机人-->
      <div class="TakeOppListWrap">
        <p v-if="isPay" class="TakeOppListTitle">请选择乘机人</p>
        <div v-if="isPay" class="TakeOppList">
          <div v-if="TakeOppList.length > 0" class="TakeOppListInfos">
            <div
              v-for="(item,index) in TakeOppList"
              :key="index"
              class="TakeOppListInfo"
              :class="{'TakeOppListInfo1' : index%3===1, 'TakeOppListInfo2': item.isSel===true,'TakeOppListInfo3':item.isSel===false}"
              :style="item.id === 9999 ? {border:'1px solid #E33F44',color: '#E33F44'} : {}"
              @click="selOpp(item)"
            >
              {{ item.name }}
            </div>
            <!--                        选中样式-->
          </div>
          <div v-else>
            <p class="NewDataOpp" @click="IncreaseOpp">新增</p>
          </div>
        </div>
        <p v-if="isPay" class="TakeOppTips">儿童/婴儿须由成人陪同乘机</p>
        <!--                选中乘机人-->
        <div class="selOpportunity">
          <div v-for="(item,index) in selTakeOppList" :key="index" class="selOpportunityList">
            <p v-if="isPay" class="Forks" @click="clearUserInfo(item)">×</p>
            <div class="userInfo">
              <p>{{ item.name }}</p>
              <p>{{ item.idTypeText }} {{ item.idCard }}</p>
            </div>
          </div>
        </div>
      </div>
      <!--            联系人-->
      <div v-if="isPay" class="selTakeOppListWrap">
        <p>联系人</p>
        <div class="infoContent">
          <div class="infoFill">
            <p class="infoFillTitle">姓名</p>
            <input v-model="form.name" type="text" class="infoFillInp" placeholder="请输入乘机人姓名">
          </div>
          <div class="van-hairline--bottom" />
          <div class="infoFill">
            <p class="infoFillTitle">手机号码</p>
            <input v-model="form.mobile" type="text" class="infoFillInp" placeholder="请输入手机号码">
          </div>
        </div>
      </div>
      <!--            报销凭证提示-->
      <div class="voucherWrap">
        <p>报销凭证：</p>
        <p>可在机场开具行程单。<br>如需邮寄行程单，需要额外支付快递费</p>
      </div>
    </div>
    <!--        底部支付-->
    <div class="btnPay">
      <div class="priceWrap">
        <p>在线支付 <span>¥{{ payPrice }}</span></p>
        <p>共{{ selTakeOppList.length }}人（{{ tickPriceInfo.baseCabinName }}）</p>
      </div>
      <div class="payWrap">
        <p v-if="isShowDetailsText" class="DetailsText" @click="lookDetail">明细</p>
        <div class="goPay" :class="isGoPay ? '' :'ProhibitionGoPay'" @click="getOder">
          <p>去支付</p>
          <!--                    <van-count-down v-if="isPay" :time="PayTime" />-->
          <!--                    <p v-if="isPay" class="payTime">{{PayTime}}</p>-->
        </div>
      </div>
    </div>
    <!--        明细-->
    <van-action-sheet v-model="showDetails" title="购买明细">
      <div class="infoDetailWrap">
        <div v-if="isShowAdultInfo" class="AdultInfoDetail">
          <div class="AdultInfoPrice">
            <p>成人</p>
            <div style="width: 240px;height: 1px;background-color:#e8e8e8;margin-left: 20px;margin-top: 7px;" />
            <p>¥{{ adultPrice }}x{{ adultNum }}人</p>
          </div>
          <div class="AdultInfoTicketPrice">
            <p>机票</p>
            <p>¥{{ adultTotalPrice }}</p>
          </div>
          <div class="AdultInfoFuelCostsPrice">
            <p>基建</p>
            <p>¥{{ adultTotalFuelCosts }}</p>
          </div>
          <div class="AdultInfoTaxPrice">
            <p>燃油</p>
            <p>¥{{ adultTotalTax }}</p>
          </div>
        </div>
        <div v-if="isShowChildrenInfo" class="ChildrenInfoDetail">
          <div class="AdultInfoPrice">
            <p>儿童</p>
            <div style="width: 240px;height: 1px;background-color:#e8e8e8;margin-left: 20px;margin-top: 7px;" />
            <p>¥{{ childrenPrice }}x{{ ChildrenNum }}人</p>
          </div>
          <div class="AdultInfoTicketPrice">
            <p>机票</p>
            <p>¥{{ ChildrenTotalPrice }}</p>
          </div>
          <div class="AdultInfoFuelCostsPrice">
            <p>基建</p>
            <p>¥{{ ChildrenTotalFuelCosts }}</p>
          </div>
          <div class="AdultInfoTaxPrice">
            <p>燃油</p>
            <p>¥{{ ChildrenTotalTax }}</p>
          </div>
        </div>
        <div class="payTips">
          <p>因为机票信息随时变化，以及网络传输的问题，价格可能存在误差，请以最终支付金额为准</p>
        </div>
      </div>
    </van-action-sheet>
    <!--        弹出层-->
    <van-popup v-model="isShowPopUp" round>{{ PopUpText }}</van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ActionSheet, CountDown, List, Loading, Popup, Sidebar, SidebarItem, Tab, Tabs, Toast, TreeSelect
} from 'vant'

Vue.use(Tab)
  .use(Tabs)
  .use(Loading)
  .use(Toast)
  .use(ActionSheet)
  .use(Sidebar)
  .use(SidebarItem)
  .use(TreeSelect)
  .use(List)
  .use(Popup)
  .use(CountDown)

import {
  getTakeOppList,
  payTicketOder,
  payRoundTripTicketOder
} from '@/services/comeOn.js'
import { weChatPay } from '@/utils/payment'

export default {
  name: 'ReturnReservationTicket',
  data() {
    return {
      ticketInfo: {},
      priceInfoD: {},
      priceInfoB: {},
      TravelText1: '去程',
      TravelText2: '返程',
      tripType: '',
      tickPriceInfo: {},
      adultFuelCostsOrtax: 0, // 成人基建燃油
      isPay: true,
      TakeOppList: [{
        name: '更多',
        id: 9999
      }], // 乘机人列表
      selTakeOppList: [], // 选中的乘机人
      form: {
        name: '',
        mobile: ''
      },
      payPrice: 0,
      isShowDetailsText: true, // 显示明细
      isGoPay: true, // 支付按钮拦截
      showDetails: false, // 明细
      isShowAdultInfo: false, // 明细显示成人
      isShowChildrenInfo: false, // 明细显示儿童
      isShowPopUp: false, // 弹出层提示
      adultNum: 0, // 成人购票人数
      adultPrice: 0, // 成人购票金额
      adultTotalPrice: 0, // 成人购票机票金额
      adultTotalFuelCosts: 0, // 成人购票基建
      adultTotalTax: 0, // 成人购票燃油
      ChildrenNum: 0, // 儿童购票人数
      childrenPrice: 0, // 儿童购票金额
      ChildrenTotalPrice: 0, // 儿童购票机票金额
      ChildrenTotalFuelCosts: 0, // 儿童购票基建
      ChildrenTotalTax: 0, // 儿童购票燃油
      PopUpText: ''
    }
  },
  created() {
    this.ticketInfo = this.$store.state.ticketListInfo
    this.priceInfoD = this.ticketInfo.depart// 去程
    this.priceInfoB = this.ticketInfo.back// 返程
    this.tickPriceInfo = this.$store.state.ticketPriceInfo
    this.startAddress = this.$route.query ? this.$route.query.startAddress : this.$route.query.startAddress
    this.endAddress = this.$route.query ? this.$route.query.endAddress : this.$route.query.endAddress
    this.childNum = this.$route.query ? this.$route.query.childNum : this.$route.query.childNum
    this.tripType = this.$route.query ? this.$route.query.tripType : this.$route.query.tripType
    if (this.tripType === 'RT') {
      this.TravelText1 = '去程'
      this.TravelText2 = '返程'
    }
    this.adultFuelCosts = Number(this.priceInfoD.oilfee) + Number(this.priceInfoB.oilfee)// 成人基建
    this.tax = Number(this.priceInfoD.tax) + Number(this.priceInfoB.tax)// 成人燃油
    this.adultFuelCostsOrtax = this.adultFuelCosts + this.tax
    // 选中的乘机人列表
    if (JSON.parse(window.localStorage.getItem('selTakeOppList'))) {
      this.selTakeOppList = JSON.parse(window.localStorage.getItem('selTakeOppList'))
    }
    this.form.name = window.localStorage.getItem('nickname')// 乘机人姓名
    this.form.mobile = window.localStorage.getItem('mobile')// 乘机人手机号
    this.getOpportunity()// get乘机人列表
  },
  mounted() {

  },
  methods: {
    // get乘机人
    getOpportunity() {
      getTakeOppList({
        mid: window.localStorage.getItem('uid')
      }).then(res => {
        if (Number(res.code) === 200) {
          this.TakeOppList = this.TakeOppList.concat(res.data.list).reverse()
          for (let i = 0; i < this.TakeOppList.length; i++) {
            this.TakeOppList[i].isSel = false
            for (let j = 0; j < this.selTakeOppList.length; j++) {
              if (this.TakeOppList[i].id !== 9999) {
                if (this.TakeOppList[i].id === this.selTakeOppList[j].id) {
                  this.TakeOppList[i].isSel = true
                  this.AmountPrice(this.TakeOppList[i], 0)
                  this.isShowDetailsText = true
                }
              } else {
                this.TakeOppList[i].isSel = false
              }
            }
          }
        }
      })
    },
    // 新增联系人
    IncreaseOpp() {
      this.$router.push({
        path: '/addOpp'
      })
    },
    // 选择乘机人
    selOpp(item) {
      if (item.id === 9999) { // 更多
        this.$router.push({
          path: '/selOpp'
        })
      } else {
        item.isSel = !item.isSel
        if (item.isSel) {
          this.selTakeOppList.push(item)
          this.AmountPrice(item, 0)
          this.isShowDetailsText = true
        } else {
          for (let i = 0; i < this.selTakeOppList.length; i++) {
            if ((this.selTakeOppList)[i].id === item.id) {
              this.selTakeOppList.splice(i, 1)
            }
          }
          this.AmountPrice(item, 1)
          if (this.selTakeOppList.length === 0) {
            this.isShowDetailsText = false
          }
        }
        window.localStorage.setItem('selTakeOppList', JSON.stringify(this.selTakeOppList))
      }
    },
    // 计算金额
    AmountPrice(item, type) {
      if (type === 0) { // 加
        if (item.type === 1) {
          // 支付金额 = 原始支付金额 + 成人机票费用 + 基建费 + 燃油费
          this.payPrice = this.payPrice + Number(this.tickPriceInfo.price) + Number(this.adultFuelCostsOrtax)
        } else {
          // 支付金额 = 原始支付金额 + 儿童支付金额 + 基建费 + 燃油费
          this.payPrice = this.payPrice + Number(this.tickPriceInfo.childPrice)
        }
      } else if (type === 1) { // 减
        if (item.type === 1) {
          // 支付金额 = 原始支付金额 - 成人机票费用 - 基建费 - 燃油费
          this.payPrice = this.payPrice - Number(this.tickPriceInfo.price) - Number(this.adultFuelCostsOrtax)
        } else {
          // 支付金额 = 原始支付金额 - 儿童支付金额 - 基建费 - 燃油费
          this.payPrice = this.payPrice - Number(this.tickPriceInfo.childPrice)
        }
      }
    },
    // 删除选中信息
    clearUserInfo(item) {
      for (let i = 0; i < this.selTakeOppList.length; i++) {
        if (this.selTakeOppList[i].id === item.id) {
          this.selTakeOppList.splice(i, 1)
          this.AmountPrice(item, 1)
        }
      }
      for (let i = 0; i < this.TakeOppList.length; i++) {
        if (this.TakeOppList[i].id === item.id) {
          this.TakeOppList[i].isSel = false
        }
      }
      if (this.selTakeOppList.length === 0) {
        this.isShowDetailsText = false
      }
      window.localStorage.setItem('selTakeOppList', JSON.stringify(this.selTakeOppList))
    },
    // 查看明细
    lookDetail() {
      for (let i = 0; i < this.selTakeOppList.length; i++) {
        if (this.selTakeOppList[i].type === 1) {
          this.isShowAdultInfo = true
          this.adultNum++
        }
        if (this.selTakeOppList[i].type === 2) {
          this.isShowChildrenInfo = true
          this.ChildrenNum++
        }
        // 成人总金额
        this.adultPrice = this.tickPriceInfo.price +
          (Number(this.priceInfoD.oilfee) +
            Number(this.priceInfoB.oilfee)) +
          (Number(this.priceInfoD.tax) +
            Number(this.priceInfoB.tax))

        // 成人机票价格
        this.adultTotalPrice = this.tickPriceInfo.price * this.adultNum
        // 成人基建费
        this.adultTotalTax = (Number(this.priceInfoD.oilfee) + Number(this.priceInfoB.oilfee)) * this.adultNum
        // 成人燃油费
        this.adultTotalFuelCosts = (Number(this.priceInfoD.tax) + Number(this.priceInfoB.tax)) * this.adultNum
        // 儿童机票价格
        this.ChildrenTotalPrice = this.tickPriceInfo.childPrice * this.ChildrenNum
      }
      this.showDetails = true
    },
    // 往返生成订单
    getOder() {
      if (this.selTakeOppList.length === 0) {
        Toast('请选择乘客')
      } else {
        this.isPay = false// 显示支付乘客信息
        this.PopUpText = '正在生成订单中。。。'
        this.isShowPopUp = true// 弹出层
        if (this.isGoPay === true) {
          this.isGoPay = false// 支付按钮禁止状态
          const flights = [this.ticketInfo.depart, this.ticketInfo.back]
          for (let i = 0; i < flights.length; i++) {
            flights[i].arrAirportName = flights[i].arrivalAirport
            flights[i].arrCity = flights[i].arrivalCity
            flights[i].depAirportName = flights[i].departureAirport
            flights[i].depCity = flights[i].departureCity
            if (i === 0) {
              flights[i].hasMeal = this.priceInfoD.haveMeal
              flights[i].baseCabin = this.tickPriceInfo.trip.baseCabin
              flights[i].cabin = this.tickPriceInfo.trip.cabin
              flights[i].luggage = this.tickPriceInfo.trip.luggage
              flights[i].priceInfoId = this.tickPriceInfo.trip.priceInfoId
            } else if (i === 1) {
              flights[i].hasMeal = this.priceInfoB.haveMeal
              flights[i].baseCabin = this.tickPriceInfo.back.baseCabin
              flights[i].cabin = this.tickPriceInfo.back.cabin
              flights[i].luggage = this.tickPriceInfo.back.luggage
              flights[i].priceInfoId = this.tickPriceInfo.back.priceInfoId
            }
          }
          const getOrderData = {
            mobile: window.localStorage.getItem('mobile'),
            memberId: window.localStorage.getItem('uid'),
            contactName: this.form.name,
            contactPhone: this.form.mobile,
            flights: flights,
            passengers: this.selTakeOppList
          }
          payRoundTripTicketOder(getOrderData).then(res => {
            this.isGoPay = true// 支付按钮恢复状态
            if (Number(res.code) === 200) {
              this.order_no = res.data.order_no
              this.goPay(res.data.order_no)
            } else {
              this.isShowPopUp = false
              this.$router.go(-2)
            }
          })
        }
      }
    },
    // 支付
    goPay(orderNo) {
      payTicketOder({
        orderNo: orderNo,
        payType: 1, // 1微信2支付宝
        paySource: 6
      }).then(res => {
        if (Number(res.code) === 200) {
          this.isShowPopUp = false// 关闭弹窗提示
          const params = res.pay.getwayBody
          this.wxPay(params)
        } else {
          this.isGoPay = true// 支付按钮恢复状态
          this.isShowPopUp = false
        }
      })
    },
    /**
     * 调用微信支付
     * 2021-06-04 10:22:15
     * @author SnowRock
     * @param params
     */
    wxPay(params) {
      var that = this
      weChatPay(params).then(res => {
        // 支付成功后的操作
        Toast('支付成功')
        that.isDisabledSubmitBtn = true
      }).catch(e => {
        if (e) {
          const { msg = null } = e
          if (msg === 'isNotWeChat') {
            Toast('请在微信浏览器下进行支付')
          }
          if (msg === 'cancel') {
            // 取消支付的操作
            Toast('取消支付')
          }
          if (msg === 'fail') {
            // 支付失败的操作
            Toast('支付失败')
          }
        }
        that.isDisabledSubmitBtn = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  background-color: #f8f8f8;
}

.content {
  position: absolute;
  text-align: left;

  .titleWrap {
    width: 100%;
    height: 42px;
    background-color: #fff;
    text-align: center;
    line-height: 42px;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1;

    .titleText {
      display: flex;
      line-height: 42px;

      p {
        font-size: 17px;
        color: #333333;
        font-weight: 600;
      }

      img {
        width: 16px;
        height: 5px;
        margin: 17px 8px;
      }

    }
  }

  .tickedTimeDetail, .tickedTimeDetail2 {
    padding: 31px 23px;
    background-color: #fff;
    margin-top: 43px;

    .AviationInfo {
      display: flex;

      p {
        line-height: 17px;
        font-size: 13px;
        color: #333333;
      }

      p:nth-child(1) {
        width: 32px;
        height: 17px;
        text-align: center;
        background-color: #E33F44;
        color: #ffffff;
        border-radius: 3px;

        span {
          display: inline-block;
          transform: scale(0.8);
        }
      }

      p:nth-child(2) {
        margin-left: 28px;
      }

      p:nth-child(3) {
        margin-left: 10px;
      }

      p:nth-child(4) {
        margin-left: 10px;
      }
    }

    .TimeInfo {
      display: flex;
      margin-top: 20px;

      .stareTimeInfo, .endTimeInfo {
        color: #333333;
        flex: 1;

        p:nth-child(1) {
          font-size: 29px;
          font-weight: 600;
        }

        p:nth-child(2) {
          font-size: 12px;
        }
      }

      .TimeImg {
        flex: 1;

        img {
          width: 52px;
          height: 6px;
          margin: 14px 28px;
        }
      }
    }

    .GiftInfo {
      width: 100%;
      text-align: center;
      margin-top: 17px;
      font-size: 12px;
      color: #999999;
    }
  }

  .tickedTimeDetail2 {
    margin-top: 0;
  }

  .RoundTripTips {
    width: 100%;
    height: 67px;
    /*margin-top: 18px;*/
    background-color: #F8F8F8;
    border-radius: 7px;
    padding: 12px 13px;
    text-align: left;
    line-height: 17px;

    p {
      font-size: 13px;
      color: #E33F44;

      span {
        color: #333333;
      }
    }
  }

  .priceTipsInfo {
    padding: 17px 0;
    background-color: #fff;

    .priceTipsInfoContent {
      text-align: left;

      .tickedListLeftContent1 {
        margin-top: 9px;
      }

      .tickedListLeftContent2 {
        margin-top: 17px;
      }

      .tickedListLeftContent {
        /*margin-left: 5px;*/
        display: flex;

        p {
          color: #333333;

          span {
            display: inline-block;
            transform: scale(0.9);
          }
        }

        img {
          width: 5px;
          height: 9px;
          margin-top: 5px;
        }
      }

      p {
        line-height: 20px;
      }

      p:nth-child(1), p:nth-child(2) {
        font-size: 14px;
        color: #333333;
      }

      p:nth-child(3) {
        font-size: 12px;
        color: #E33F44;
      }
    }
  }

  /*乘机人*/

  .TakeOppListWrap {
    padding: 18px 13px;
    margin-top: 7px;
    background-color: #fff;

    .TakeOppListTitle {
      font-size: 16px;
      color: #333333;
      font-weight: 600;
    }

    .TakeOppList {
      margin-top: 10px;
      margin-bottom: 13px;

      .TakeOppListInfos {
        display: flex;
        flex-wrap: wrap;

        .TakeOppListInfo, .NewDataOpp {
          width: 112px;
          height: 38px;
          text-align: center;
          line-height: 38px;
          font-size: 12px;
          border-radius: 3px;
          margin-bottom: 7px;
          border: 1px solid #999999;
        }

        .TakeOppListInfo {
          color: #999999;
        }

        .TakeOppListInfo1 {
          margin-left: 6px;
          margin-right: 6px;
        }

        .TakeOppListInfo2 {
          border: 1px solid #E33F44;
          color: #E33F44;
        }

      }

      .NewDataOpp {
        color: #E33F44;
      }
    }

    .TakeOppTips {
      font-size: 12px;
      color: #666666;
    }

    /*选中乘机人*/

    .selOpportunity {
      .selOpportunityList {
        display: flex;
        padding: 17px 0;

        .Forks {
          width: 17px;
          height: 17px;
          font-size: 20px;
          border: 1px solid #E33F44;
          color: #E33F44;
          border-radius: 50%;
          text-align: center;
          line-height: 15px;
          margin-top: 10px;
        }

        .userInfo {
          color: #333333;
          margin-left: 18px;

          p:nth-child(1) {
            font-size: 17px;
            font-weight: 600;
          }

          p:nth-child(2) {
            font-size: 14px;
            margin-top: 8px;
          }
        }
      }
    }

  }

  .selTakeOppListWrap {
    margin-top: 7px;
    background-color: #fff;
    padding: 15px 13px;

    p:nth-child(1) {
      font-size: 16px;
      color: #333333;
      font-weight: 600;
    }

    .infoContent {
      margin-top: 7px;

      .infoFill {
        display: flex;
        height: 54px;
        line-height: 54px;

        .infoFillTitle {
          width: 30%;
          font-size: 14px;
          color: #666666;
        }

        .infoFillInp {
          width: 60%;
          background-color: #fff;
        }

        .iconStyle {
          margin-left: auto;
          line-height: 54px;
        }
      }
    }
  }

  .voucherWrap {
    display: flex;
    font-size: 12px;
    color: #E33F44;
    padding: 15px;
    line-height: 15px;
    background-color: #f8f8f8;
  }

  /*底部支付*/

  .btnPay {
    width: 100%;
    height: 75px;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    padding: 18px 14px;

    .priceWrap {
      p:nth-child(1) {
        font-size: 18px;
        color: #242424;
        font-weight: 600;

        span {
          color: #E33F44;
        }
      }

      p:nth-child(2) {
        margin-top: 9px;
        font-size: 13px;
        color: #999999;
      }
    }

    .payWrap {
      margin-left: auto;
      display: flex;

      .DetailsText {
        font-size: 13px;
        color: #999999;
        line-height: 45px;
      }

      .goPay {
        margin-left: 20px;
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 17px;
        color: #ffffff;
        background-color: #E33F44;
        border-radius: 20px;

        .payTime {
          font-size: 12px;
        }
      }

      .ProhibitionGoPay {
        background-color: #999999;
      }
    }
  }

  /*    购买明细*/

  .infoDetailWrap {
    padding: 23px 17px;
    text-align: left;

    .AdultInfoDetail, .ChildrenInfoDetail {
      .AdultInfoPrice, .AdultInfoTicketPrice, .AdultInfoFuelCostsPrice, .AdultInfoTaxPrice {
        display: flex;
        color: #333333;

        p:nth-child(1) {
          margin-bottom: 18px;
        }

        p:nth-child(2) {
          margin-left: auto;
        }
      }

      .AdultInfoTicketPrice, .AdultInfoFuelCostsPrice, .AdultInfoTaxPrice {
        font-size: 12px;

        p:nth-child(2), p:nth-child(3), p:nth-child(4) {
          margin-bottom: 12px;
        }
      }

      .AdultInfoPrice {
        p {
          font-size: 14px;
        }

        p:nth-child(3) {
          margin-left: auto;
        }
      }
    }

    .payTips {
      margin-top: 14px;

      p {
        font-size: 12px;
        color: #E33F44;
      }
    }
  }
}

/deep/ .van-popup--center.van-popup--round {
  padding: 20px;
}
</style>
